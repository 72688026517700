<template>
  <div class="downloadBox">
    <template v-if="client === 'A'">
      <div
        class="button"
        v-if="$isWeiXin()"
        v-clipboard:copy="url"
        v-clipboard:success="() => $toast('复制成功')"
        v-clipboard:error="() => $toast('复制失败')"
      >
        点击复制并在浏览器中打开
      </div>
      <div class="button" @click="download" v-else>立即下载APP</div>
    </template>
    <template v-else>
      <div class="button">IOS暂无开放, 请使用网页版</div>
    </template>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      url: '',
      client: '',
    };
  },
  created() {
    this.client = this.$judgePhone();
    this.url = `${location.origin}/hyys-2.0.2.apk`;
    this.$http('get', '/v1/version').then(res => {
      this.url = res.data.url;
    });
  },
  mounted() {},
  methods: {
    download() {
      window.location.href = this.url;
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.downloadBox {
  background: url('~@/assets/images/other/download.png') no-repeat center;
  background-size: cover;
}
.button {
  position: fixed;
  bottom: 170px;
  left: 30px;
  .dfjccaic;
  width: 690px;
  height: 90px;
  background: #525298;
  border-radius: 10px;

  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}
</style>
